import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { DoubleMeasurement, Measurement, StringMeasurement } from '@/shared/models/dto/measurement';
import LanguageStore from '../../../stores/language-store';
import { FilterOperator } from '../../../shared/models/shared/filter-operator';

@Component({
    components: {
        apexchart: VueApexCharts
    }
})
export default class SensorChartComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly measurements: DoubleMeasurement[] | StringMeasurement[];
    @Prop({ default: false }) readonly isComplex: boolean;
    @Prop({ default: '' }) readonly sensorName: string;
    @Prop({ default: null, required: false }) readonly startDate: Date;
    @Prop({ default: null, required: false }) readonly endDate: Date;

    isDoubleMeasurements: boolean = true;

    get headers() {
        return [
            { text: this.t('TimeAdded'), value: 'timeAdded', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Value'), value: 'stringValue', filterOperator: FilterOperator.contains, inputType: 'string', }
        ];
    }

    series: any[] = [{
        data: []
    }];

    items: any[] = [];

    options = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['timeAdded']
    };

    totalCount: number = 0;

    availableHeaders = [];

    get chartOptions() {
        const lang = LanguageStore.getLanguage().languageCode;
        return {
            chart: {
                locales: this.apexChartLocales,
                defaultLocale: lang,
                zoom: {
                    enabled: this.isComplex
                },
                type: 'line',
                maxheight: 500,
                toolbar: {
                    show: this.isComplex
                },
                events: {
                    beforeZoom: (e, { xaxis }) => {
                        let maindifference = this.getMin() - this.getMax();
                        let zoomdifference = xaxis.max - xaxis.min;
                        if (xaxis.min < this.getMin()) {
                            if (zoomdifference > maindifference) {
                                return {
                                    // dont zoom out any further
                                    xaxis: {
                                        min: this.getMin(),
                                        max: this.getMax()
                                    }
                                };
                            }
                            else {
                                return {
                                    // keep on zooming
                                    xaxis: {
                                        min: xaxis.min,
                                        max: xaxis.max
                                    }
                                }
                            }
                        }
                    }
                }
            },
            series: [],
            stroke: {
                width: 3,
                curve: 'straight'
            },
            tooltip: {
                x: {
                    format: 'dd MMMM HH:mm:ss'
                }
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                },
                min: this.getMin(),
                max: this.getMax()
            }
        };
    }

    @Watch('measurements', { immediate: true })
    measurementsChanged() {
        if (!this.isStringMeasurements(this.measurements)) {
            this.isDoubleMeasurements = true;
            this.setSeries();
        }
        else {
            this.isDoubleMeasurements = false;
        }
    }

    isStringMeasurements(measurements: DoubleMeasurement[] | StringMeasurement[]): measurements is StringMeasurement[] {
        return (<StringMeasurement[]>measurements).every(p => p.stringValue !== undefined);
    }

    setSeries() {
        let data = [];
        this.measurements.forEach((item) => data.push([item.timeAdded.getTime(), item.value]));     
        this.series = [{
            name: this.sensorName,
            data: data
        }];                   
    }   

    getMin() {
        const measurements = this.measurements as DoubleMeasurement[];
        if (measurements.length) {
            const min = measurements.reduce((a, b) => a.timeAdded < b.timeAdded ? a : b);
            return min.timeAdded.getTime();
        }
        else if (this.startDate) {
            return new Date(this.startDate).getTime()
        }
    }

    getMax() {
        const measurements = this.measurements as DoubleMeasurement[];

        if (measurements.length) {
            const max = measurements.reduce((a, b) => a.timeAdded > b.timeAdded ? a : b);
            return max.timeAdded.getTime();
        }
        else if (this.endDate) {
            return new Date(this.endDate).getTime()
        }
    }
}